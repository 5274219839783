import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'yup';

import { ValidationType } from '@enums';
import { createFormField } from '@utils';

export const useSignInFormSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return object().shape({
      ...createFormField('username', {
        required: true,
        pattern: ValidationType.EMAIL,
      }),
      ...createFormField('password', {
        required: true,
        minLength: 8,
      }),
    });
  }, [t]);
};
