import { ValidationType } from '@enums/globals/validation';
import { ValidationRegExp } from './validationRegExp';

interface PatternRule {
  regex: RegExp;
  type: string;
  format: string;
}

export const ValidationPatterns: Record<ValidationType, PatternRule> = {
  // Basic patterns
  [ValidationType.LATIN_LETTERS]: {
    regex: ValidationRegExp.LATIN_LETTERS,
    type: 'validation:types.format',
    format: 'validation:formats.latinLetters',
  },
  [ValidationType.SINGLE_LATIN_LETTER]: {
    regex: ValidationRegExp.SINGLE_LATIN_LETTER,
    type: 'validation:types.format',
    format: 'validation:formats.singleLatinLetter',
  },
  [ValidationType.ALPHANUMERIC]: {
    regex: ValidationRegExp.ALPHANUMERIC,
    type: 'validation:types.format',
    format: 'validation:formats.alphanumeric',
  },
  [ValidationType.NUMBERS]: {
    regex: ValidationRegExp.NUMBERS,
    type: 'validation:types.format',
    format: 'validation:formats.numbers',
  },
  [ValidationType.FIRST_CHAR_OF_WORD]: {
    regex: ValidationRegExp.FIRST_CHAR_OF_WORD,
    type: 'validation:types.format',
    format: 'validation:formats.firstCharOfWord',
  },

  // Number formats
  [ValidationType.DECIMAL_NUMBERS]: {
    regex: ValidationRegExp.DECIMAL_NUMBERS,
    type: 'validation:types.format',
    format: 'validation:formats.decimalNumbers',
  },
  [ValidationType.DECIMAL_TWO_PLACES]: {
    regex: ValidationRegExp.DECIMAL_TWO_PLACES,
    type: 'validation:types.format',
    format: 'validation:formats.decimalTwoPlaces',
  },

  // Name validation
  [ValidationType.NAME]: {
    regex: ValidationRegExp.NAME,
    type: 'validation:types.format',
    format: 'validation:formats.name',
  },

  // Common validations
  [ValidationType.EMAIL]: {
    regex: ValidationRegExp.EMAIL,
    type: 'validation:types.emailAddress',
    format: 'validation:formats.email',
  },
  [ValidationType.IP_ADDRESS]: {
    regex: ValidationRegExp.IP_ADDRESS,
    type: 'validation:types.ipAddress',
    format: 'validation:formats.ipAddress',
  },
  [ValidationType.URL]: {
    regex: ValidationRegExp.URL,
    type: 'validation:types.url',
    format: 'validation:formats.url',
  },
  [ValidationType.PHONE]: {
    regex: ValidationRegExp.PHONE,
    type: 'validation:types.phoneNumber',
    format: 'validation:formats.phone',
  },
  [ValidationType.DATE]: {
    regex: ValidationRegExp.DATE_ISO,
    type: 'validation:types.date',
    format: 'validation:formats.date',
  },
  [ValidationType.TIME]: {
    regex: ValidationRegExp.TIME_24H,
    type: 'validation:types.time',
    format: 'validation:formats.time',
  },
} as const;
