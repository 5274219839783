import { useField } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DualListBox, FormModal } from '@components';
import { columnDirection, Dictionaries, FormType, Translation } from '@enums';
import { useFormSubmission } from '@hooks';
import { getOptionListFromCatalog } from '@utils';

interface BlockRegionModalProps {
  label: any;
  open: boolean;
  onClose: () => void;
  formik: any;
  name: string;
  formType: FormType;
  onSubmit: (event, formik, name) => void;
}

export const BlockRegionModal = ({ name, formType, formik, onSubmit, label, open, onClose }: BlockRegionModalProps) => {
  const { t: tModals } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.security' });

  const [field] = useField<string[]>(name);

  const regions = useMemo(() => getOptionListFromCatalog(Dictionaries.Regions) ?? [], []);

  useFormSubmission({ formType });

  return (
    <FormModal
      title={label?.title}
      formType={formType}
      open={open}
      onClose={onClose}
      description={label?.description}
      size="2xl"
      confirmButton={{
        children: label?.confirmButton,
        onClick: () => {
          onSubmit(field.value, formik, name);
        },
      }}
    >
      <DualListBox
        options={regions}
        leftPanel={{
          title: tModals('availableRegions'),
          fieldName: 'availableRegions',
          placeholder: label.placeholder,
        }}
        rightPanel={{
          title: tModals('blockedRegions'),
          fieldName: 'blockedRegions',
          placeholder: label.placeholder,
        }}
        name={name}
        direction={columnDirection.Row}
        showValue
      />
    </FormModal>
  );
};
