import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { object, type TestContext } from 'yup';

import { Translation, ValidationType } from '@enums';
import { createFormField, validateValueEqual } from '@utils';

export const useCreateUserAccountFormSchema = () => {
  const { t: tValidation } = useTranslation(Translation.Validation);

  return useMemo(() => {
    return object().shape({
      ...createFormField('firstname', {
        required: true,
        pattern: ValidationType.NAME,
        minLength: 2,
        maxLength: 50,
      }),
      ...createFormField('lastname', {
        required: true,
        pattern: ValidationType.NAME,
        minLength: 2,
        maxLength: 50,
      }),
      ...createFormField('username', {
        required: true,
        pattern: ValidationType.EMAIL,
      }),
      ...createFormField('password', {
        required: true,
        minLength: 8,
      }),
      ...createFormField('confirm_password', {
        required: true,
        custom: {
          validator: function (this: TestContext, value: string) {
            return validateValueEqual(this?.parent?.password, value, 'string');
          },
          message: tValidation('changePassword.passwordsMustMatch'),
        },
      }),
    });
  }, [tValidation]);
};
