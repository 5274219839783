import { motion } from 'framer-motion';
import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useNavigate } from 'react-router-dom';

import CompanyBg from '@assets/loginBg.webp';
import CompanyLogo from '@assets/logo.webp';

import { GlobalLoader } from '@components';
import { ROUTES } from '@constants';
import { UserNextAction } from '@enums';
import { useInvitation } from '@hooks';
import { useSystemIsOnline, useUserNextAction } from '@store';
import type { RecaptchaRef } from '@types';
import { validateValueEqual } from '@utils';

interface WithRecaptchaRef {
  recaptchaRef?: RecaptchaRef;
}
export const LoginLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isOnline = useSystemIsOnline();
  const nextAction = useUserNextAction();

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const img = new Image();
    img.src = CompanyBg;
    img.onload = () => setIsImageLoading(false);
  }, []);

  useInvitation();

  useEffect(() => {
    if (nextAction && nextAction.type === UserNextAction.VERIFY_EMAIL) {
      navigate(ROUTES.reverifyEmail);
    }
    return () => {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    };
  }, [isOnline, nextAction]);

  if (isImageLoading) return <GlobalLoader overlay />;

  const isLogin = validateValueEqual(pathname, ROUTES.login);
  const siteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

  return (
    <div className="relative h-full w-full overflow-y-auto">
      <motion.div className="fixed inset-0">
        <img src={CompanyBg} alt="Global Network" className="w-full h-full object-cover" />
      </motion.div>
      <div className="relative min-h-screen w-full overflow-y-auto bg-gradient-to-br from-gray-900/50 to-gray-800/50">
        <div className="flex min-h-screen w-full items-center justify-center py-8 px-4">
          <motion.div className="bg-theme-neutral-dark backdrop-blur-sm bg-opacity-90 backdrop-filter ring-1 ring-white/10 rounded-xl shadow-glow px-8 pb-9 pt-8 w-full max-w-sm">
            <div className="flex flex-col gap-8 px-1">
              {!!isLogin && (
                <motion.div className="flex flex-col items-center space-y-6 pt-1">
                  <img className="h-16 md:h-20 w-auto" src={CompanyLogo} alt="Company Logo" />
                </motion.div>
              )}
              {Children.map(children, (child) => {
                if (isValidElement(child)) {
                  return cloneElement(child as ReactElement<WithRecaptchaRef>, {
                    recaptchaRef,
                  });
                }
                return child;
              })}
            </div>
          </motion.div>
          <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} theme="light" size="invisible" className="z-50" />
        </div>
      </div>
    </div>
  );
};
