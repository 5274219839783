import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Skeleton } from '@components';
import { Direction, DisplayMode, InputMod, VariantType } from '@enums';
import { validateValueEqual } from '@utils';

import ErrorMessage from './errorMessage';
import { InputWithIcons } from './inputWithIcons';

interface InputTextProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  name: string;
  multiline?: boolean;
  errorClassName?: string;
  isCorrect?: boolean;
  hints?: string;
  fontSize?: string;
  adornment?: React.ReactNode;
  withoutRing?: boolean;
  adornmentWithoutM?: boolean;
  rounded?: string;
  fullWidth?: boolean;
  customizeColor?: boolean;
  transform?: (event: React.ChangeEvent<HTMLInputElement>) => React.ChangeEvent<HTMLInputElement>;
  smallFont?: boolean;
  variant?: VariantType;
  clearFunction?: () => void;
  inputMod?: InputMod;
  withoutClear?: boolean;
  label?: string;
  autoComplete?: string;
  isError?: boolean;
  adornmentPosition?: Direction;
  loading?: boolean;
  displayMode?: DisplayMode;
  withoutErrorMessage?: boolean;
}

export const TextInput: React.FC<InputTextProps> = ({
  multiline = false,
  name,
  errorClassName = '',
  isCorrect,
  isError = false,
  hints,
  fontSize,
  type = 'text',
  onBlur,
  transform,
  adornment,
  withoutRing,
  customizeColor = false,
  onPaste,
  disabled,
  autoComplete = 'off',
  smallFont = false,
  className = `py-1.5 ${type === 'number' ? 'pr-16' : 'pr-10'}`,
  variant = VariantType.Normal,
  inputMod,
  adornmentWithoutM = false,
  fullWidth,
  clearFunction,
  maxLength,
  min,
  max,
  required = false,
  displayMode = DisplayMode.Light,
  withoutClear,
  rounded = 'rounded-md',
  adornmentPosition = Direction.Left,
  value,
  loading = false,
  onChange,
  label,
  placeholder,
  withoutErrorMessage = false,
}) => {
  const [field, meta, helpers] = useField(name);
  const formik = useFormikContext();

  const [inputState, setInputState] = useState({
    showClearIcon: false,
    isHide: 'password',
    userHasInteracted: false,
  });
  const [padding, setPadding] = useState({ right: '10px', left: '0px', iconRight: '12px' });

  const adornmentRef = useRef<HTMLDivElement>(null);
  const iconsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const previousPaddingRef = useRef({
    right: '',
    left: '',
    iconRight: '',
  });
  const stateTransitionRef = useRef(false);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const isCustom = validateValueEqual(variant, VariantType.Custom);
  const isFilled = validateValueEqual(inputMod, InputMod.Filled);
  const isPassword = validateValueEqual(type, 'password');
  const isNumber = validateValueEqual(type, 'number');
  const isAdornmentLeft = validateValueEqual(adornmentPosition, Direction.Left);
  const isAdornmenRight = validateValueEqual(adornmentPosition, Direction.Right);
  const isAdornmentOutside = validateValueEqual(adornmentPosition, Direction.Outside);
  const isLightTheme = validateValueEqual(displayMode, DisplayMode.Light);

  const isFieldError = useMemo(
    () => Boolean(meta.error && (meta.initialTouched || meta.touched || formik.submitCount > 0)),
    [meta.error, meta.initialTouched, meta.touched, formik.submitCount],
  );

  useEffect(() => {
    const calculatePadding = (
      adornmentWidth: number,
      iconsWidth: number,
    ): { right: string; left: string; iconRight: string } => ({
      right: `${(isAdornmentLeft ? adornmentWidth : 0) + iconsWidth + 3}px`,
      left: `${!isAdornmentLeft ? adornmentWidth + 10 : isFilled ? (adornment ? 12 : 16) : 12}px`,
      iconRight: `${(isAdornmentLeft ? adornmentWidth : 0) + (isFilled ? 14 : meta.touched ? 8 : 10)}px`,
    });

    const updatePadding = () => {
      const adornmentWidth = adornmentRef.current?.offsetWidth ?? 0;
      const iconsContainerWidth = iconsContainerRef.current?.offsetWidth ?? 0;

      const newPadding = calculatePadding(adornmentWidth, iconsContainerWidth);

      if (
        newPadding.right !== previousPaddingRef.current.right ||
        newPadding.left !== previousPaddingRef.current.left ||
        newPadding.iconRight !== previousPaddingRef.current.iconRight
      ) {
        previousPaddingRef.current = newPadding;
        setPadding(newPadding);
      }
    };

    const debouncedUpdate = debounce(() => {
      requestAnimationFrame(updatePadding);
    }, 100);

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(debouncedUpdate);

    if (iconsContainerRef.current) {
      resizeObserverRef.current.observe(iconsContainerRef.current);
    }
    if (adornmentRef.current) {
      resizeObserverRef.current.observe(adornmentRef.current);
    }

    updatePadding();

    return () => {
      debouncedUpdate.cancel();
      resizeObserverRef.current?.disconnect();
    };
  }, [isAdornmentLeft, isFilled, adornment, meta.touched]);

  const handleClear = useCallback(() => {
    helpers.setValue('');
    clearFunction?.();
  }, [helpers, clearFunction]);

  const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (stateTransitionRef.current) return;

    stateTransitionRef.current = true;
    requestAnimationFrame(() => {
      setInputState((prev) => {
        if (!prev.userHasInteracted || !prev.showClearIcon) {
          return { ...prev, showClearIcon: true, userHasInteracted: true };
        }
        return prev;
      });
      stateTransitionRef.current = false;
    });

    event.target.focus();
  }, []);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (stateTransitionRef.current) return;

      stateTransitionRef.current = true;

      const processValue = (value: string): string | number => {
        const processedValue: string | number = value?.trim?.() ?? value;

        if (isNumber && min !== undefined) {
          const numValue = Number(processedValue);
          if (isNaN(numValue) || numValue < Number(min)) {
            return Number(min);
          }
          return numValue;
        }

        return processedValue;
      };

      const trimmedValue = processValue(event.target.value);

      requestAnimationFrame(() => {
        setInputState((prev) => ({
          ...prev,
          showClearIcon: false,
          userHasInteracted: false,
        }));

        const syntheticEvent: NumberInputEvent = {
          target: {
            name: field.name,
            value: trimmedValue,
          },
        };

        field.onChange(syntheticEvent);
        field.onBlur(event);
        onBlur?.(event);

        if (event.target && event.target instanceof HTMLInputElement) {
          event.target.value = trimmedValue.toString();
        }

        stateTransitionRef.current = false;
      });
    },
    [field.name, field.onChange, field.onBlur, isNumber, min, onBlur],
  );

  const handleHideIcon = useCallback(() => {
    setInputState((prev) => ({
      ...prev,
      isHide: prev.isHide === 'password' ? ('text' as const) : ('password' as const),
    }));
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const transformedEvent = transform ? transform(event) : event;
      field.onChange(transformedEvent);
      onChange?.(transformedEvent);
    },
    [field, transform, onChange],
  );

  type NumberInputEvent = {
    target: {
      name: string;
      value: string | number;
    };
  };

  const disableNumberInputScroll = useCallback(
    (e: React.WheelEvent<HTMLInputElement>) => {
      if (isNumber) {
        e.currentTarget.blur();
      }
    },
    [isNumber],
  );
  useEffect(() => {
    return () => {
      stateTransitionRef.current = false;
    };
  }, []);

  if (loading) {
    return <Skeleton className={classNames('h-10', { 'w-full': !adornment || fullWidth, [rounded]: rounded })} />;
  }

  return (
    <motion.div
      className={classNames('flex flex-col h-full space-y-3', { 'w-full': fullWidth })}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-1 gap-3">
        <motion.div className="relative group flex flex-1 items-center">
          {adornment && isAdornmenRight && (
            <div
              ref={adornmentRef}
              className={classNames('absolute left-0 h-full flex', { 'pl-3': !adornmentWithoutM })}
            >
              {adornment}
            </div>
          )}
          <motion.input
            {...field}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value ?? field.value ?? ''}
            autoComplete={autoComplete}
            required={required}
            onFocus={handleFocus}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChange={handleChange}
            onPaste={onPaste}
            disabled={disabled}
            type={isPassword ? inputState.isHide : type}
            min={isNumber ? min : undefined}
            max={isNumber ? max : undefined}
            aria-invalid={!!(meta.error && meta.touched)}
            aria-describedby={isFieldError ? `${name}-error` : undefined}
            className={classNames('block border-0 text-ellipsis placeholder-gray-400 hover:cursor-text', {
              'w-full': !adornment || fullWidth,
              'sm:leading-6': fontSize,
              'px-2.5 py-1.5': !isCustom && !isFilled,
              'px-3': !isCustom && isFilled,
              'text-us': smallFont,
              'text-sm': !smallFont,
              'ring-1 ring-theme-border-default focus:ring-indigo-600': !withoutRing,
              'resize-y': multiline,
              'bg-gray-50 bg-opacity-10': !isFieldError && !customizeColor,
              'bg-theme-neutral-main/70 text-white': !isFieldError && !isLightTheme,
              'ring-theme-error-main ring-1 bg-red-50 focus:ring-theme-error-main':
                isFieldError || (isError && withoutErrorMessage && isLightTheme),
              'ring-theme-error-main ring-1 bg-red-500/10 focus:ring-theme-error-main':
                isFieldError || (isError && withoutErrorMessage && !isLightTheme),
              'w-full h-11 text-sm peer outline-none border-0 rounded-lg px-4 py-3 transition-all duration-300 focus:ring-2':
                isFilled,
              'pt-9 pb-4 placeholder-transparent': isFilled && field.value,
              'group-focus-within:pt-9 peer-valid:pt-9 peer-valid:pb-4 group-focus-within:pb-4 group-focus-within:placeholder-gray-400 placeholder-transparent':
                isFilled && (!field.value || field.value === ''),
              'bg-theme-primary-light': disabled && !customizeColor,
              '[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [-moz-appearance:textfield]':
                isNumber,
              [rounded]: rounded,
              [className]: className,
            })}
            style={{ paddingRight: padding.right, paddingLeft: padding.left }}
            animate={{ scale: inputState.userHasInteracted && !adornment ? 1.02 : 1 }}
            onWheel={disableNumberInputScroll}
            transition={{ duration: 0.15 }}
          />
          <AnimatePresence>
            {isFilled && !!label && (
              <motion.label
                htmlFor={name}
                transition={{ duration: 0.2 }}
                animate={{ x: inputState.userHasInteracted && !adornment ? -1.05 : 0 }}
                className={classNames(
                  'absolute top-0 left-0 flex h-full items-center transform transition-all text-gray-400 font-medium pointer-events-none select-none',
                  'group-focus-within:h-4/6',
                  {
                    'text-smLabel peer-valid:h-4/6 peer-invalid:h-4/6 peer-disabled:h-4/6': field.value,
                    'text-xs group-focus-within:text-smLabel text-[0.58rem]': !field.value,
                    'pl-4': !adornment,
                    'pl-3': adornment,
                  },
                )}
              >
                {label}
              </motion.label>
            )}
          </AnimatePresence>
          <InputWithIcons
            isError={isFieldError}
            isCorrect={isCorrect}
            field={field}
            iconInputRef={iconsContainerRef}
            withoutClear={withoutClear}
            hints={hints}
            isNumber={isNumber}
            paddingRight={padding.iconRight}
            clearFunction={clearFunction}
            isPassword={isPassword}
            isHide={inputState.isHide}
            handleHideIcon={handleHideIcon}
            handleClear={handleClear}
            showClearIcon={inputState.showClearIcon}
          />
          {adornment && isAdornmentLeft && (
            <div ref={adornmentRef} className={`absolute right-0 h-full flex ${adornmentWithoutM ? '' : 'pr-3'}`}>
              {adornment}
            </div>
          )}
        </motion.div>
        {adornment && isAdornmentOutside && (
          <div className={classNames('h-full flex', { 'pr-2': !adornmentWithoutM })}>{adornment}</div>
        )}
      </div>
      <AnimatePresence>
        {!withoutErrorMessage && isFieldError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ErrorMessage isError={isFieldError} errorClassName={errorClassName} meta={meta} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
