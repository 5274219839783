import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '@components';
import { ROUTES } from '@constants';
import { CREATE_NEW_PASSWORD_FORM_CONFIG } from '@constants/pages';
import { FormActionType, FormType, Translation, UserEndpoint } from '@enums';
import { useAppDispatch, useCreateNewPasswordFormSchema, useErrorFormInner } from '@hooks';
import { submitFormRequest, updateFormProcedure, useFormError, useFormSubmissionStatus } from '@store';
import type { CreateNewPasswordFormValues } from '@types';
import { LoginButtonGroup, LoginFormFieldWrapper } from '../../subComponents';

export const CreateNewPasswordForm = ({ email, token }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: tSignIn } = useTranslation(Translation.SignIn);
  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });

  const formType = FormType.User;
  const newPasswordFormMetaData = { formType, endpoint: UserEndpoint.RESET_PASSWORD };

  const validationSchema = useCreateNewPasswordFormSchema();
  const isFormSubmitting = useFormSubmissionStatus(newPasswordFormMetaData);
  const isFormError = useFormError(newPasswordFormMetaData);

  const handleSubmit = (values: CreateNewPasswordFormValues) => {
    dispatch(
      updateFormProcedure({
        formType,
        procedure: FormActionType.ResetPassowrd,
      }),
    );
    dispatch(
      submitFormRequest({
        formType,
        formData: { email, token, ...values },
        globalOptions: {
          endpoint: UserEndpoint.RESET_PASSWORD,
          toast: true,
        },
        callbackFunction: {
          refetch: () => navigate(ROUTES.login),
        },
      }),
    );
  };

  const NewPasswordFormInner = () => {
    useErrorFormInner({ submitError: isFormError, isSubmitting: isFormSubmitting });
    return null;
  };

  const { fields, initialValues } = CREATE_NEW_PASSWORD_FORM_CONFIG(email);

  return (
    <Formik
      initialValues={initialValues as CreateNewPasswordFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="space-y-5 pt-2">
          <NewPasswordFormInner />
          {fields.map((field) => (
            <LoginFormFieldWrapper
              key={field.name}
              field={{
                ...field,
                label: (
                  <Text $level={5} $customizeColor className="text-slate-400">
                    {tSignIn(String(field.label))}
                  </Text>
                ),
                fullWidth: true,
                customizeColor: true,
                withoutRing: true,
                smallFont: true,
                textInputClassName:
                  'bg-theme-neutral-main text-white rounded-lg focus:ring-2 focus:ring-theme-primary transition-all duration-300',
              }}
              translation={tSignIn}
            />
          ))}
          <LoginButtonGroup
            label={{ confirmButton: tButton('savePassword') }}
            formParams={newPasswordFormMetaData}
            hasNavigate
          />
        </Form>
      )}
    </Formik>
  );
};
