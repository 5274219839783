export enum ValidationType {
  // Basic patterns
  LATIN_LETTERS = 'LATIN_LETTERS',
  SINGLE_LATIN_LETTER = 'SINGLE_LATIN_LETTER',
  FIRST_CHAR_OF_WORD = 'FIRST_CHAR_OF_WORD',
  ALPHANUMERIC = 'ALPHANUMERIC',
  NUMBERS = 'NUMBERS',

  // Number formats
  DECIMAL_NUMBERS = 'DECIMAL_NUMBERS',
  DECIMAL_TWO_PLACES = 'DECIMAL_TWO_PLACES',

  // Name validation
  NAME = 'NAME',

  // Common validations
  EMAIL = 'EMAIL',
  IP_ADDRESS = 'IP_ADDRESS',
  URL = 'URL',
  PHONE = 'PHONE',
  DATE = 'DATE',
  TIME = 'TIME',
}
